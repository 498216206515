import React, { useCallback, useEffect, useRef, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import "./modal.css";

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ru } from 'date-fns/locale/ru';

import { useWindowSize } from "../../utils/media-query";
import { formatMessage } from "devextreme/localization";

export default function AddMultiTasksForm({ isVisible, onSave, onClose }) {

    const windowSize = useWindowSize();
    registerLocale('ru', ru)
    //   const dataGrid  = useRef(null);
    //   const updateRef = useCallback((grid) => {
    //     dataGrid.current = grid;
    //     if (dataGridRef) {
    //       dataGridRef.current = grid;
    //     }
    //   }, [dataGridRef]);


    //   useEffect(()=>dataGrid?.current?.instance?.updateDimensions(), [windowSize])

    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState([]);
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onAddItem()
            // setItems([...items, inputValue.trim()]);
            // setInputValue('');
        }
    };

    const onAddItem = () => {
        if (inputValue.trim() !== '' && !items.includes(inputValue.trim())) {
            let cuttedString = inputValue.trim();
        if(/,|;|\s/.test(cuttedString)){
            cuttedString = cuttedString.split('"').join('');
            let values = cuttedString.split(/,|;|\s/)
            values = values.filter((item)=>{
               return !items.includes(item.trim()) && item.trim() !== '';
            })
            setItems([...items, ...new Set(values)]);
            setInputValue('');
        }else{
            setItems([...items, cuttedString]);
            setInputValue('');
        }
        }
    }

    const handleRemoveItem = (index) => {
        const updatedItems = items.filter((item, i) => i !== index);
        setItems(updatedItems);
    };

    const clearForm = () => {
        setItems([])
        setDateFrom()
        setDateTo()
    }

    const handleClose = () => {
        clearForm()
        onClose()
    }
    const handleSave = (data) => {
        onSave(data)
        clearForm()
    }

    return <>
        <Modal
            isOpen={isVisible}
            onRequestClose={handleClose}
            className="Modal"
            overlayClassName="Modal__overlay"
        >
            <h3>Множественная постановка задач</h3>
            <button className="Modal__close" onClick={handleClose}>
                ✖
            </button>
            <div className='modalContent'>
                <div className='codesList'>
                    <div className='codesView'>
                    <ul>
                        {items.map((item, index) => (
                            <li className='codeTag' key={index}>{item} <button className='removeButton' onClick={() => handleRemoveItem(index)}>✖</button></li>
                        ))}
                    </ul>
                    </div>
                    
                    <input
                        className='codeInput'
                        type="text"
                        size={50}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Введите рег номер СИ и нажмите Enter"
                    />
                    <button onClick={onAddItem}>Добавить</button>
                </div>
                {/* <div className='dateBlock'> */}
                <div className="dateFrom">
                    <span>Дата с: </span>
                    <DatePicker showIcon isClearable dateFormat={'dd.MM.yyyy'} selected={dateFrom} onChange={(date) => {

                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const isoDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
                        setDateFrom(isoDateTimeString)
                    }} />
                </div>
                <div className="dateTo">
                    <span>Дата по: </span>
                    <DatePicker showIcon isClearable dateFormat={'dd.MM.yyyy'} selected={dateTo} onChange={(date) => {
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const isoDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
                        setDateTo(isoDateTimeString)
                    }} />
                </div>



                {/* </div > */}
                <div className='buttonsBlock'>
                    <button className='saveButton' onClick={() => handleSave({ items, dateFrom, dateTo })}>Сохранить</button>

                    <button className='cancelButton' onClick={handleClose}>Отменить</button>
                </div>
            </div>

        </Modal>

    </>
}