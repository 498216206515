import React, {useMemo} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts';
import authProvider from "./api/authProvider";
import {useAuth} from "./contexts/auth";

export default function Content() {

  const { user  } = useAuth();
  const routesFiltered = useMemo(()=>{
    return routes.filter( (r
    ) => r.role === undefined || authProvider.hasRole(r.role) )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        {routesFiltered.map(({ path, component }) => (
          <Route
            exact
            key={path}
            path={path}
            component={component}
          />
        ))}
        <Redirect to={'/'} />
      </Switch>
    </SideNavBarLayout>
  );
}
