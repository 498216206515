import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm } from './components';
import { formatMessage } from "devextreme/localization";

export default function UnauthenticatedContent() {
  return (
    <Switch>
      <Route exact path='/login' >
        <SingleCard title={ formatMessage('login_title') }>
          <LoginForm />
        </SingleCard>
      </Route>
      {/*<Route exact path='/create-account' >*/}
      {/*  <SingleCard title="Sign Up">*/}
      {/*    <CreateAccountForm />*/}
      {/*  </SingleCard>*/}
      {/*</Route>*/}
      <Route exact path='/reset-password' >
        <SingleCard
          title={ formatMessage('login_restorePassword') }
          description={ formatMessage('login_restorePasswordDesc') }
        >
          <ResetPasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/change-password/:recoveryCode' >
        <SingleCard title={ formatMessage('login_changePasswordTitle') }>
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}
