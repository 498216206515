import React, { createContext, useContext, useCallback } from 'react';
import {useMergeState} from "../components/hook-utils";
import { Toast } from 'devextreme-react/toast';

export function MessagesProvider({children}) {
  const [state, setState] = useMergeState({ message: '', visible: false });

  const showMessage = useCallback(async (message, type, displayTime) => {
    setState({
      message: ''+(message || 'ОШИБКА!'),
      type: type || 'error',
      displayTime: displayTime || ((type || 'error') === 'error' ? 50000 : 5000 ),
      visible: true,
      rnd: new Date().valueOf(),
      onHiding: () => setState({ visible: false }),
      closeOnClick: true,
      closeOnOutsideClick: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MessagesContext.Provider value={{ showMessage }} >
      {children}
      <Toast {...state} />
    </MessagesContext.Provider>
  );
}

const MessagesContext = createContext({});
export const useMessages = () => useContext(MessagesContext);
