import {baseApiUrl} from "../../api/constants";
import useInterval from "../../utils/useInterval";
import {useMessages} from "../../contexts/messages";
import React, {useCallback, useRef, useState, useEffect} from 'react';
import './jobs.scss';
import FullscreenGrid from "../../components/fullscreen-grid/fullscreen-grid";
import {Api} from "../../api/apiProvider";
import {Column, Lookup, Button,  Toolbar, Item, Popup } from "devextreme-react/data-grid";
import {formatMessage} from "devextreme/localization";
import AddMultiTasksForm from "../../components/add-multi-tasks-form/add-multi-tasks-form"
// import fileSaver from "file-saver";

import 'devextreme-react/radio-group';

function isRunIconVisible(e) {
  const status = e?.row?.data?.status;
  return status === 0;
}

function isStopIconVisible(e) {
  const status = e?.row?.data?.status;
  return status !== 0 && status !== 4;
}

function isDeleteIconVisible(e) {
  const status = e?.row?.data?.status;
  return status === 4 || status === 0;
}

function isDownloadIconVisible(e) {
  const status = e?.row?.data?.status;
  return status === 4 || status === 3 || status === 2;
}

export default function Jobs() {
  const { showMessage } = useMessages();

  const [isVisible, setIsVisible] = useState(false)
  const [visibleRows, setVisibleRows] = useState([])

  const ref = useRef(null);
  const start = useCallback((e) => {
    const id = e?.row?.data?.id;
    if (id) {
      Api.actions.jobs.start(id).then( ()=> ref?.current?.instance?.refresh()).catch(showMessage)
    }
  }, [showMessage]);
  const stop = useCallback((e) => {
    const id = e?.row?.data?.id;
    if (id) {
      Api.actions.jobs.stop(id).then( ()=> ref?.current?.instance?.refresh()).catch(showMessage)
    }
  }, [showMessage]);

  const handleCopyRow = useCallback((e)=> {
    const row = e?.row?.data
    const gridInstance = ref?.current?.instance

    const newRow = {reg_number: row.reg_number, period_from: row.period_from, period_to: row.period_to}
    Api.stores.jobs.insert(newRow).then((resp)=>{
      gridInstance.refresh()
      //.then(()=> gridInstance.editRow(resp.id))
      
    }).catch(showMessage)
   
  },[showMessage])

  const downloadXls = useCallback((e) => {
    const id = e?.row?.data?.id;
    if (id) {
      window.open(`${baseApiUrl}/excel/jobs/${id}`);
      showMessage(`Скачивание Excel файла по заданию ${id}`,'info');
      // Api.actions.jobs.excel(id).then(responseBody => fileSaver.saveAs(
      //   new Blob([responseBody], {type: "application/octet-stream"}),
      //   `Задание N${+id}.xlsx`
      // )).then(() => showMessage('Файл успешно загружен', 'success')).catch(showMessage);
    }
  },[showMessage]);

  // const countIfNeeded = (rows) => {
  //   if(visibleRows.length !== 0 && visibleRows !== rows){
  //     const newRows = rows;
  //     const ff = newRows.filter(el => el.data.status === 4 && visibleRows.find((elem)=> elem.key === el.key).data.status === 3)
  //     if(ff.length !== 0){
  //       ff.map(row=>  Api.actions.jobs.count(row.key))
  //     }
  //   }
  //   setVisibleRows(rows)
  // }


  useInterval(() => {
    const rows = ref?.current?.instance?.getVisibleRows();
    const needUpdate = rows?.reduce( (acc,el) => acc || el?.data?.status === 1 || el?.data?.status === 2 ||el?.data?.status === 3, false);
    if (needUpdate) {
      //countIfNeeded(rows)
      ref?.current?.instance?.refresh();
    }
  }, 20000);

  const closeModal = () =>{
    setIsVisible(false)
  }

  const saveMulti = (data) => {
    const gridInstance = ref?.current?.instance
    data.items.map((item) => {
      const newRow = {reg_number: item, period_from: data.dateFrom, period_to: data.dateTo}
      Api.stores.jobs.insert(newRow).then((resp)=>{
        Api.actions.jobs.start(resp.id)
        //.then(()=> gridInstance.editRow(resp.id))
       // gridInstance.refresh()
      }).catch(showMessage)
    })
    setTimeout(() =>gridInstance.refresh(),2000)
    closeModal()
  }

  return <>
  <AddMultiTasksForm isVisible={isVisible} onSave={saveMulti} onClose={closeModal} />
    <FullscreenGrid dataSource={Api.stores.jobs} dataGridRef={ref}>
      <Column dataField="reg_number" dataType="string" caption={ formatMessage('jobs_reg_number') }  />
      <Column dataField="year" dataType="number" caption={ formatMessage('jobs_year') }  />
      <Column dataField="period_from" dataType="date" caption={ formatMessage('jobs_period_from') }  />
      <Column dataField="period_to" dataType="date" caption={ formatMessage('jobs_period_to') }  />
      <Column dataField="count_first" dataType="number" allowEditing={false} caption={'Всего первичных'}/>
      <Column dataField="status" dataType="number"
              caption={ formatMessage('jobs_status') } allowEditing={false} >
        <Lookup
          dataSource={Api.dictionary.statuses}
          valueExpr="id"
          displayExpr="name"
        />
      </Column>
      <Column dataField="count_rows" dataType="number" allowEditing={false} caption={ formatMessage('jobs_count_rows') }/>
      <Column dataField="current_row" dataType="number" allowEditing={false} caption={ formatMessage('jobs_current_row') }/>
      <Column dataField="current_item" dataType="number" allowEditing={false} caption={ formatMessage('jobs_current_item') }/>
      <Column type="buttons" width={150}>
        <Button hint="Скачать файл" icon="xlsfile" onClick={downloadXls} visible={isDownloadIconVisible}/>
        <Button hint="Запустить" icon="video" onClick={start} visible={isRunIconVisible}/>
        <Button hint="Остановить" icon="square" onClick={stop} visible={isStopIconVisible}/>
        <Button name="edit" visible={isRunIconVisible}/>
        <Button hint="Копировать" icon="copy" onClick={handleCopyRow} visible={true}/>
        <Button name="delete" visible={isDeleteIconVisible}/>
      </Column>
      <Toolbar>
        <Item name="addRowButton" />
        <Item name="columnChooserButton" />
        <Item widget="dxButton"
        options={{
        hint:"Множественная постановка задач",
        icon:"orderedlist",
        }}
        onClick={()=>setIsVisible(true)}
        />
        <Item name="searchPanel" />
      </Toolbar>
    </FullscreenGrid>
  </>;

}
