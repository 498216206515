import authProvider from "./authProvider";

export async function signIn(username, password) {
  try {
    const isOk = await authProvider.login({username,password});

    return {
      isOk,
      data: authProvider.getUser()
    };
  }
  catch {
    return {
      isOk: false,
      message: "Ошибка авторизации"
    };
  }
}

export async function signOut() {
  try {
    await authProvider.logout();
    return {
      isOk: true,
      data: authProvider.getUser()
    };
  }
  catch {
    return {
      isOk: false,
      message: "Ошибка"
    };
  }
}


export async function getUser() {
  try {
    // Send request

    return {
      isOk: authProvider.isAuthorized(),
      data: authProvider.getUser()
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
