export const navigation = [
  {
    text: 'Задания на парсинг',
    path: '/',
    icon: 'key'
  },
  {
    text: 'Пользователи',
    path: '/users',
    icon: 'user',
    role: 'admin'
  }
];
