import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './dx-styles.scss';
import React, {useEffect} from 'react';
import { HashRouter as Router } from 'react-router-dom';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import ruMessages from "devextreme/localization/messages/ru.json";
import ruCustomMessages from "./messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import {MessagesProvider} from "./contexts/messages";

function App() {
  const { user, loading } = useAuth();

  useEffect( () => {
    loadMessages(ruMessages);
    loadMessages(ruCustomMessages);
    locale("ru");
  }, [])

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <MessagesProvider>
                <App />
            </MessagesProvider>
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
