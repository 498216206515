import React from 'react';
import {formatMessage} from "devextreme/localization";
import FullscreenGrid from "../../components/fullscreen-grid/fullscreen-grid";
import { Column, Lookup, RequiredRule,EmailRule } from 'devextreme-react/data-grid';
import {Api} from "../../api/apiProvider";

export default function Users() {

  return <FullscreenGrid dataSource={Api.stores.users}>
    <Column dataField="login" caption={formatMessage('users_login')}>
      <EmailRule/>
    </Column>
    <Column dataField="name" caption={formatMessage('users_name')}>
      <RequiredRule/>
    </Column>
    <Column dataField="role_id" caption={formatMessage('users_role')}>
      <Lookup
        dataSource={Api.stores.roles}
        valueExpr="id"
        displayExpr="name"
      />
    </Column>
    <Column dataField="new_p" caption={formatMessage('users_password')} allowSearch={false}
            customizeText={(_) => '* * *'}
            visible={false}
    >
    </Column>
  </FullscreenGrid>;

}
