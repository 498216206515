import { baseApiUrl } from './constants';
import { createBrowserHistory } from 'history';

export const rolesHierarchy = { 'viewer': 0b001, 'editor': 0b011, 'admin': 0b111 };
export const roles = { 'viewer': 0b001, 'editor': 0b010, 'admin': 0b100 };

export const history = createBrowserHistory();

const isAuthorized = () => {
  return localStorage.getItem('token') != null;
};

const getUser = () => {
  let user = {};
  const token = localStorage.getItem('token');
  if (token && token.indexOf('.')>0)
    user = JSON.parse(atob(token.split('.')[1]));
  return { ...user, email: user.username, role: user.role };
};

const authProvider = {
  login: ({ username, password }) =>  {
    const request = new Request(`${baseApiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token }) => {
        localStorage.setItem('token', access_token);
        return true;
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (localStorage.getItem('token') != null && (status === 401 || status === 403)) {
      localStorage.removeItem('token');
      history.go(0);
      return Promise.reject(error);
    }
    if (status >= 400)
      return Promise.reject(error);
    return Promise.resolve(error);
  },
  isAuthorized,
  getUser,
  hasRole: (needRole) => {
    if (!isAuthorized())
      return false;
    const { role } = getUser();
    if (needRole === role)
      return true;
    const needRoleId = roles[needRole];
    const roleContains = rolesHierarchy[role] & needRoleId;
    return (roleContains>0);
  }
};

export default authProvider;
