import React, {useCallback, useEffect, useRef, useState} from 'react';
import './fullscreen-grid.scss';

import {
  DataGrid,
  ColumnChooser,
  FilterRow,
  Scrolling,
  ColumnFixing,
  SearchPanel,
  Editing,
  Popup,
  Form,
  Column,
  FormItem,
  LoadPanel,
  Button,
  Paging,
  Pager

} from 'devextreme-react/data-grid';

import {useWindowSize} from "../../utils/media-query";
import {formatMessage} from "devextreme/localization";
import authProvider from "../../api/authProvider";

export const idColumn = () => <Column dataField="id" allowEditing={false} dataType="number" caption={formatMessage('id')}>
  <FormItem visibleIndex={100}/>
</Column>;
export const datesColumns = () => [
  <Column dataField="created_at" allowSearch={false} allowEditing={false} dataType="date" caption={formatMessage('created_at')} key="c_101">
    <FormItem visibleIndex={101}/>
  </Column>,
  <Column dataField="updated_at" allowSearch={false} allowEditing={false} dataType="date" visible={false}
          caption={formatMessage('updated_at')}  key="c_102">
    <FormItem visibleIndex={102}/>
  </Column>];

const elementAttr = {
  id: 'gridContainer'
};

export default function FullscreenGrid({ dataSource, children, formTitle, disableAdditionalColumns, onInitNewRow, onOptionChanged, items, allowChange, onToolbarPreparing, dataGridRef }) {

  const windowSize = useWindowSize();
  const dataGrid  = useRef(null);
  const updateRef = useCallback((grid) => {
    dataGrid.current = grid;
    if (dataGridRef) {
      dataGridRef.current = grid;
    }
  }, [dataGridRef]);

  const allowedPageSizes = [25, 50, 100];


  useEffect(()=>dataGrid?.current?.instance?.updateDimensions(), [windowSize])


  return <React.Fragment>
    <div className={'content-block'}>
      <DataGrid id="dataGrid" dataSource={dataSource}
                rowAlternationEnabled={true}
                ref={updateRef}
                allowColumnResizing={true}
                columnAutoWidth={true}
                allowColumnReordering={true}
                showBorders={true}
                remoteOperations={true}
                elementAttr={elementAttr}
                repaintChangesOnly={true}
                onInitNewRow={onInitNewRow}
                onOptionChanged={onOptionChanged}
                onToolbarPreparing={onToolbarPreparing}
      >
        {
          allowChange !== false && authProvider.hasRole('editor') &&
          <Editing
            mode="popup"
            allowUpdating
            allowAdding
            allowDeleting
            useIcons={true}
            refreshMode="repaint"
          >
            <Popup title={formTitle || "Редактирование"} showTitle={true} maxWidth={700}
                   closeOnOutsideClick={true}
            />
            <Form colCount={1} showRequiredMark={true} showColonAfterLabel={false}>
              {items !== undefined ? items : []}
            </Form>
          </Editing>
        }
           <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={'full'}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <LoadPanel enabled={false} />
        <Scrolling mode="virtual" />
        <ColumnChooser enabled={true} />
        <ColumnFixing enabled={true} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        {disableAdditionalColumns !== true && idColumn()}
        {children}
        {disableAdditionalColumns !== true && datesColumns()}
      </DataGrid>
    </div>
  </React.Fragment>
}
