import { baseApiUrl } from './constants';
import authProvider from "./authProvider";
import CustomStore from 'devextreme/data/custom_store';
// import DataSource from "devextreme/data/data_source";

const reDateDetect = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/;  // startswith: 2015-04-29T22:06:55

export function getFile(url, body, method) {
  const request = new Request(`${baseApiUrl}/${url}`, {
    mode: 'cors',
    method: method || 'GET',
    body: body === undefined ? undefined : JSON.stringify(body),
    headers: new Headers({
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }),
  });
  return fetch(request)
    .then(authProvider.checkError)
    .then( p => p.arrayBuffer());
}

export function getApi(url, body, method) {
  const request = new Request(`${baseApiUrl}/${url}`, {
    mode: 'cors',
    method: method || 'GET',
    body: body === undefined ? undefined : JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }),
  });
  return fetch(request)
    .then(authProvider.checkError)
    .then(response => {
        if (response.status < 200 || response.status >= 300) {
          return new Promise( (resolve, reject) => {
            response.json().then((err) => { reject(new Error(err.errorText || `Ошибка ${response.status}`)) });
          })
        }
        return response.text()
      })
    .then( response => JSON.parse(response,function(key, value) {
        if (typeof value == 'string' && (reDateDetect.exec(value))) {
          return new Date(value);
        }
        return value;
      }));
}

function genStore(url, _keyName, children) {
  const keyName = _keyName || 'id';
  return new CustomStore({ key: keyName, loadMode: "processed",
    load: (loadOptions) => getApi(url, {...loadOptions, children }, "POST"),
    byKey: (key) => getApi(url, { filter: [ keyName, '=', key], take: 1, children }, "POST")
      .then( r => r && r.data && r.data[0]),
    update: (key, values) => getApi(`${url}/${key}`, { ...values, children }, "PATCH"),
    insert: (values) => getApi(`${url}/new`, { ...values, children }, "POST"),
    remove: (key) => getApi(`${url}/${key}`, undefined, "DELETE")
  });
}

// const orgs = genStore('orgs');
// const keys = genStore('keys');
export const Api = {
  actions: {
    jobs: {
      start: (id) => getApi(`jobs/${id}/start`),
      stop: (id) => getApi(`jobs/${id}/stop`),
      excel: (id) => getFile(`excel/jobs/${id}`),
      count: (id) => getApi(`jobs/${id}/count`),
    }
  },
  stores: {
    users: genStore('users'),
    roles: genStore('roles'),
    jobs: genStore('jobs')
  },
  dictionary: {
    statuses: [
      { id: 0, name: "В проекте"},
      { id: 1, name: "В работе"},
      { id: 2, name: "Парсинг списка"},
      { id: 3, name: "Парсинг типа"},
      { id: 4, name: "Закончен"}
    ]
  }
  // dataSources: {
  //   orgs_lookup: { store: orgs, select: ['id','name'], paginate: true, pageSize: 100, sort: [{ selector: 'name' }] },
  //   keys_subgrid: { store: keys, paginate: true, pageSize: 100, filter: ['id','=',0] },
  //   keys_ds: new DataSource({ store: keys }),
  //   // keys_meters: new DataSource({ store: keys_meters })
  // },
  // keys_getNext: () => getApi(`keys/next`)
};
